// ==========================================================================
// Menu module
// ==========================================================================
import Module from './Module'

class MenuModule extends Module {
    constructor(options) {
        super();
        this.options = options;

        this.hasScroll = false;

        this.events();

        $(window).on('scroll.mmes.menu', this.scroll.bind(this));

    }

    toggle() {

        $('.js-nav').toggleClass('menu-is-open');
        $('html,body').toggleClass('menu-is-open');

    }

    scroll() {
        if (window.pageYOffset > 0 && !this.hasScroll) {
            this.hasScroll = true;
            $('body').addClass('menu-is-fit');
        }

        if (window.pageYOffset < 1) {
            this.hasScroll = false;
            $('body').removeClass('menu-is-fit');
        }
    }

    events() {
        $('body').on('click.mmes.menu', '.js-nav-toggle', this.toggle);
    }

    destroy() {
        $('.js-nav').removeClass('menu-is-open');
        $('html,body').removeClass('menu-is-open');
        $(document).off('.mmes.menu');
    }
}

export default MenuModule;
