// ==========================================================================
// Partner module
// ==========================================================================
import Module from './Module';

class Partner extends Module {
    constructor(options) {
        super();
        this.$el = options.$el;

        this.toggleButton = this.$el.find('.js-partner-toggle');
        this.toggleLabel = this.$el.find('.js-partner-label');
        this.viewbox = this.$el.find('.js-partner-viewbox');
        this.partnerGrid = this.$el.find('.js-partner-grid');
        this.item = this.$el.find('.js-partner-item').first();
        this.items = this.$el.find('.js-partner-item');

        $(document).ready(() => {
            this.init();
        });
    }

    // ==========================================================================
    // Initialisation
    // ==========================================================================

    init() {

        var self = this;

        var $img = $('img'), // images collection
            totalImg = $img.length,
            waitImgDone = function() {
                totalImg--;
                if (!totalImg) {
                    self.resize();
                }
            };
        $img.each(function() {
            if (this.complete) waitImgDone(); // already here..
            else $(this).load(waitImgDone).error(waitImgDone); // completed...
        });

        this.events();
    }

    // ==========================================================================
    // Event Listeners
    // ==========================================================================

    events() {
        $(window).resize(this.windowResized.bind(this));
        this.toggleButton.on('change.mmes.partner', this.resize.bind(this));
    }

    // ==========================================================================
    // Functions
    // ==========================================================================

    windowResized(){
        this.disableTransition();

        this.resize();
    }

    resize() {
        this.viewbox.height(this.getSize() + 'px');
    }

    disableTransition() {
        clearTimeout(this.timer);
        if(!this.viewbox.hasClass('disable-transition')) {
            this.viewbox.addClass('disable-transition');
        }

        var self = this;

        this.timer = setTimeout(function(){
            self.viewbox.removeClass('disable-transition');
        }, 500);
    }

    getSize() {
        if (this.toggleButton[0].checked) {
            return this.partnerGrid.outerHeight();
        }else{
            return this.item.outerHeight() * this.rowNum();
        }
    }

    rowNum() {
        if(this.partnerGrid.outerHeight() > this.item.outerHeight() *2) {
            this.toggleLabel.show();
            return 2;
        }
        else if(this.partnerGrid.outerHeight() > this.item.outerHeight()) {
            this.toggleLabel.hide();
            return 2;
        } else {
            this.toggleLabel.hide();
            return 1;
        }
    }

    // Destroy
    // ==========================================================================
    destroy() {
        this.$el.off('.mmes.partner');
        $(document).off('.mmes.partner');
    }
}

export default Partner;
