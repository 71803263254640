// ==========================================================================
// Module
// ==========================================================================

class Module {
    constructor() {
        this.$window = $(window);
        this.$html = $(document.documentElement);
        this.$body = $(document.body);
    }
}

export default Module;
