export { default as AnchorScroll } from './modules/AnchorScroll';
export { default as ArrowAnimation } from './modules/ArrowAnimation';
export { default as Contact } from './modules/Contact';
export { default as Generic } from './modules/Generic';
export { default as Globals } from './modules/Globals';
export { default as GoogleMap } from './modules/GoogleMap';
export { default as LabelBetter } from './modules/LabelBetter';
export { default as LoaderAnimation } from './modules/LoaderAnimation';
export { default as Menu } from './modules/Menu';
export { default as Newsletter } from './modules/Newsletter';
export { default as PageTransitions } from './modules/PageTransitions';
export { default as Partner } from './modules/Partner';
export { default as ScrollAnimations } from './modules/ScrollAnimations';
export { default as Search } from './modules/Search';
export { default as Slider } from './modules/Slider';
