// ==========================================================================
// Globals module
// ==========================================================================
import Svg from './Svg';
import PageTransitions from './PageTransitions';
import ScrollAnimations from './ScrollAnimations';
import Menu from './Menu';

class Globals {
    constructor() {
        new Svg();
        new PageTransitions();
        this.animations = new ScrollAnimations();
        this.menu = new Menu();

        FastClick.attach(document.body);
    }
}

export default Globals;
