// ==========================================================================
// Newsletter module
// ==========================================================================
import Module from './Module';

class Newsletter extends Module {
    constructor(options) {
        super();
        this.$el = options.$el;

        this.init();
    }

    // ==========================================================================
    // Initialisation
    // ==========================================================================

    init() {
        this.events()
    }

    // ==========================================================================
    // Event Listeners
    // ==========================================================================

    events() {
        $('.js-newsletter-form')
            .data('form-working', false)
            .on('submit.mmes.newsletter', this.submitForm.bind(this));
    }

    // ==========================================================================
    // Form Handling
    // ==========================================================================

    submitForm(e) {
        var self = this;

        e.preventDefault();
        e.stopPropagation();

        var $form = $(e.currentTarget),
            $submit,
            formData,
            xhrRequest;

        // prevent multi submit
        if ($form.data('form-working') || $form.prop('disabled')) {
            return;
        }

        $form.data('form-working', true).prop('diasbled', true);

        formData = new FormData(e.currentTarget);

        self.validate($form);

        xhrRequest = {
            type       : (e.formMethod || $form.prop('method') || 'POST'),
            url        : (e.formAction || $form.prop('action')),
            processData: false,
            contentType: false,
            data       : formData,
            success    : function (response) {
                if (response.success === true) {
                    self.success($form);
                    $form.trigger('reset');
                } else {
                    self.fail();
                }
            },
            error      : function (error) {
                var errors = error.responseText;
                errors     = JSON.parse(errors);
                errors     = errors.errors;
                self.fail(errors);
            },
            complete   : function (error) {
                $form.data('form-working', false).removeProp('disabled');
                //$submit.removeClass('is-loading');
            }
        };

        $('.js-newsletter-loader').addClass('loading');

        $.ajax(xhrRequest);
    }

    // Validate inputs
    // ==========================================================================
    validate($form) {

        // Activate submit button, on change
        $('[required]', $form).each(function () {
            var $this = $(this);

            if (!$this.val()) {
                $this.addClass('has-error');
            }
        });
    }

    // On form submit success
    // ==========================================================================
    success($form) {
        $form.empty().append(
            '<div class="c-form_success">Merci de votre intérêt, un courriel vous à été envoyé afin de confirmer votre inscription.</div>'
        );
    }

    // On form submit fail
    // ==========================================================================
    fail(errors = []) {

        for (var i = 0; i < errors.length; i++) {

            console.log(errors[i]);
        }

        $('.js-newsletter-loader').removeClass('loading');

    }

    // Destroy
    // ==========================================================================
    destroy() {
        this.$el.off('.mmes.newsletter');
        $(document).off('.mmes.newsletter');
    }

}

export default Newsletter;
