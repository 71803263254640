// ==========================================================================
// ScrollAnimationsModule module
// ==========================================================================
import Module from './Module'

class ScrollAnimations extends Module {

    constructor(options) {
        super();
        this.options = options;
        this.deviceSupported = !((/Android|iPhone|iPad|iPod|BlackBerry|Windows Phone/i).test(navigator.userAgent || navigator.vendor || window.opera));

        $(document).ready(this.load.bind(this)).ready(this.events.bind(this));
    }

    load() {
        this.wow = new WOW({
            boxClass    : 'js-wow',
            animateClass: 'is-animated',
            callback    : function (box) {
                // the callback is fired every time an animation is started
                // the argument that is passed in is the DOM node being animated
                //  console.log('callback wow');
            }
        }).init();

        if (this.canRenderSkrollr()) {
            this.s = skrollr.init({
                forceHeight: false
            });
            if (this.s.isMobile()) {
                this.s.destroy();
            }
        }
    }


    canRenderSkrollr() {
        return (typeof window.matchMedia !== 'undefined' && window.matchMedia('(min-width: 1024px)').matches) && this.deviceSupported;
    }

    refresh() {
        if (this.s) {
            this.s.destroy();
        }
        if (this.canRenderSkrollr()) {
            this.s = skrollr.init({
                forceHeight: false
            });

            if (this.s.isMobile()) {
                this.s.destroy();
            }
        }
    }

    events() {}
}

export default ScrollAnimations;
