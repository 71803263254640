// ==========================================================================
// Search module
// ==========================================================================
import Module from './Module'

class Search extends Module {
    constructor(options) {
        super();
        this.$el = options.$el;

        // Animation
        // ==========================================================================
        // this.s      = null;
        // this.s_bbox = null;
        // this.b      = null;
        // this.b_bbox = null;
        // this.c      = null;
        // this.c_bbox = null;

        Ractive.DEBUG = false;

        this.searchActive     = false;
        this.searchData       = null;
        this.searchController = this.initSearchController();
        this.events();

        this.results  = this.$el.find('.js-search-results');
        this.isMobile = this.$el.data('search-mobile');

        $('.js-search-select').selectric({
            maxHeight: 350
        });
    }

    // ==========================================================================
    // Events Listeners
    // ==========================================================================

    events() {
        this.$el.find('.js-search-select').on('change', this.selectHandler.bind(this));
        this.$el.find('.js-search-submit').on('click', this.searchButtonHandler.bind(this));

        $(document).on('click.mmes.search', '.js-scroll-to', (e) => {
            this.scrollTo(e);
        });

    }

    // ==========================================================================
    // Handlers
    // ==========================================================================

    searchButtonHandler() {
        if (this.searchActive) {
            this.closeResult();
        } else {
            this.initSearch();
        }
    }

    selectHandler() {
        this.initSearch();
    }

    // ==========================================================================
    // Ajax request for search data fetching
    // ==========================================================================

    initSearchData() {
        this.loading = true;
        // this.buttonStateLoader();
        this.$el.addClass('button-loading').removeClass('button-exit');

        $.ajax({
            url        : this.$el.find('form').attr('action'),
            type       : 'GET',
            dataType   : 'json',
            success    : (obj, textStatus) => {
                console.log(obj);
                console.log(textStatus);
                var order = this.reorderResults(obj);

                var articles = {};
                var nav      = {};

                for (var i = 0; i < order.length; i++) {
                    articles[order[i].ident] = {
                        content  : obj[order[i].ident] || [],
                        type     : order[i].name,
                        isNews   : order[i].ident == 'news',
                        isAction : order[i].ident == 'activity',
                        isEvent  : order[i].ident == 'event',
                        numActive: (obj[order[i].ident] || []).length
                    };

                    for (var j = 0; j < articles[order[i].ident].content.length; j++) {
                        articles[order[i].ident].content[j].filter = true;
                    }
                }

                // this.stopLoader();

                this.searchController.set('articles', articles);
                this.searchController.set('nav', nav);

                window.app.initModules($(".c-results").get(0));

                this.filterSearch();
                return articles;
            },
            error      : (xhr, textStatus, errorThrown) => {
                console.log(textStatus);
                console.log(errorThrown);
            },
            processData: false,
            contentType: false
        });

    }

    initSearchController() {

        var ractive = new Ractive({
            el              : '.js-search',
            template        : $('.js-search').html(),
            data            : {
                category: '',
                sector  : '',
                articles: []
            },
            delimiters      : ['[[', ']]'],
            tripleDelimiters: ['[[[', ']]]']
        });

        return ractive;
    }

    // ==========================================================================
    // Search Filtering
    // ==========================================================================

    initSearch() {
        if (this.searchData === null) {
            this.searchData = this.initSearchData();
        } else {
            this.filterSearch();
        }
    }

    filterSearch() {
        if (!this.searchActive) {
            this.openResult();
        }

        if (this.isMobile) {
            this.openResult();
        }

        var cat     = this.$el.find('.js-search-select[name="category"]').val();
        var sector  = this.$el.find('.js-search-select[name="sector"]').val();
        var ractive = this.searchController;

        var articles = ractive.get('articles');
        for (var article in articles) {
            var category = articles[article];

            ractive.set('articles.' + article + '.numActive', 0);
            var numActive = 0;

            for (var i = 0; i < category.content.length; i++) {
                let el = category.content[i];


                if (el.cat === cat && el.sector === sector) {
                    ractive.set('articles.' + article + '.content[' + i + '].filter', true);
                    numActive++;
                    ractive.set('articles.' + article + '.numActive', numActive);
                } else {
                    ractive.set('articles.' + article + '.content[' + i + '].filter', false);
                }
            }
        }

        this.updateFilters();
    }

    updateFilters() {
        var cat    = this.$el.find('.js-search-select[name="category"]').val();
        var sector = this.$el.find('.js-search-select[name="sector"]');

        // reset the display of sector
        sector.children('option').prop('disabled', 'disabled');

        var ractive  = this.searchController;
        var articles = ractive.get('articles');
        for (var article in articles) {
            var category = articles[article];

            for (var i = 0; i < category.content.length; i++) {
                let el = category.content[i];

                if (el.cat === cat) {
                    sector.find('[value="' + el.sector + '"]').prop('disabled', false);
                }
            }
        }

        var selectric = $('.js-search-select');
        selectric.selectric('refresh');

        if (!sector.val()) {
            sector.val(sector.find('option:not(:disabled)').first().val()).selectric('refresh');
            this.filterSearch();
        }
    }

    // ==========================================================================
    // Reorder Search Results
    // ==========================================================================

    reorderResults(results) {

        var order  = [];
        var news   = {
            ident: "news",
            name : "Actualités"
        };
        var action = {
            ident: "activity",
            name : "Actions"
        };
        var event  = {
            ident: "event",
            name : "Événements"
        };

        switch ($('.js-template-ident').data('template')) {
            case 'news':
                order = [news, action, event];
                break;
            case 'activities':
                order = [action, news, event];
                break;
            case 'events':
                order = [event, news, action];
                break;
            default:
                order = [news, action, event];
                break;
        }

        return order.filter(function(obj) {
            return typeof results[obj.ident] !== 'undefined';
        });
    }

    // ==========================================================================
    // Handling search results toggle
    // ==========================================================================

    closeResult() {
        this.searchActive = false;
        $('html').removeClass('search-results-open');
        this.results.height(0).css('min-height', 'initial');
        this.results.removeClass('-opened');

        // Change button state
        // this.buttonStateSearch();
        this.$el.removeClass('button-exit', 'button-loading');
    }

    openResult() {

        var self = this;

        this.searchActive = true;

        if (this.isMobile) {
            self.results.height('100%').css('min-height', '100vh');
            $('html, body').animate({
                scrollTop: self.$el.offset().top + self.$el.outerHeight()
            }, 500, function () {
                $('html, body').animate({
                    scrollTop: self.$el.offset().top + self.$el.outerHeight()
                }, 100, function () {
                    // self.results.addClass('-opened');
                    // Change button state
                    // self.buttonStateExit();
                    self.$el.addClass('button-exit').removeClass('button-loading');
                })
            });
        } else {

            $('html').addClass('search-results-open');
            $('html, body').animate({
                scrollTop: self.$el.offset().top - $('.js-nav').outerHeight()
            }, 500, function () {
                $('html, body').animate({
                    scrollTop: self.$el.offset().top - $('.js-nav').outerHeight()
                }, 100, function () {
                    self.results.height(window.innerHeight - self.$el.find('.js-search_bar').outerHeight() - $('.js-nav').outerHeight());
                    self.results.addClass('-opened');
                    // Change button state
                    // self.buttonStateExit();
                    self.$el.addClass('button-exit').removeClass('button-loading');
                })
            });
        }
    }

    // ==========================================================================
    // ANIMATIONS
    // ==========================================================================


    // svg() {
    //     if (!this.s) {
    //         this.s      = Snap('#search-anim');
    //         this.s_bbox = this.s.getBBox();
    //         this.b      = this.s.select('#branches');
    //         this.b_bbox = this.b.getBBox();
    //         this.c      = this.s.select('#circle');
    //         this.c_bbox = this.c.getBBox();
    //     }
    //     return this.s;
    // }
    //
    // buttonStateLoader() {
    //
    //     var self    = this;
    //     var s       = this.svg();
    //     var s_bbox  = this.s_bbox;
    //     var bb      = s.selectAll('.b_bottom');
    //     var bb_bbox = bb.getBBox();
    //     var tb      = s.selectAll('.b_top');
    //     var tb_bbox = tb.getBBox();
    //     var c       = s.select('#circle');
    //     // b.animate({transform: "T-4, -4"}, 500);
    //
    //     c.animate({transform: "S1.25 1.25, " + c.getBBox().cx + ", " + c.getBBox().cy + " T" + (15 - c.getBBox().cx) + " " + (15 - c.getBBox().cy) + " "}, 200, mina.easeinout, function (f) {
    //         bb.animate({transform: "R-30, " + bb_bbox.x + "," + bb_bbox.y + " "}, 200, mina.easeinout);
    //         tb.animate({transform: "R30, " + tb_bbox.x2 + "," + tb_bbox.y2 + " "}, 200, mina.easeinout, function () {
    //             self.rotateLoader();
    //         });
    //     });
    // }
    //
    // rotateLoader() {
    //
    //     var self = this;
    //
    //     var s      = this.svg();
    //     var b      = s.select('#branches');
    //     var s_bbox = this.s_bbox;
    //
    //     if (this.loading) {
    //         b.animate({transform: "R360, " + 15 + ", " + 15 + " "}, 500, mina.easeinout, function () {
    //             b.attr({transform: "R0, " + 15 + ", " + 15 + " "});
    //             self.rotateLoader();
    //         });
    //     }
    //
    // }
    //
    //
    // stopLoader() {
    //     this.loading = false;
    // }
    //
    //
    // buttonStateExit() {
    //     var s       = this.svg();
    //     var s_bbox  = this.s_bbox;
    //     var b       = s.select('#branches');
    //     var b_bbox  = this.b_bbox;
    //     var c       = s.select('#circle');
    //     var c_bbox  = this.c_bbox;
    //     var bb      = s.selectAll('.b_bottom');
    //     var bb_bbox = bb.getBBox();
    //     var tb      = s.selectAll('.b_top');
    //     var tb_bbox = tb.getBBox();
    //     var b1      = s.select('#branch1');
    //
    //
    //     bb.animate({transform: "R0, " + bb_bbox.x + "," + bb_bbox.y + " "}, 200, mina.easeinout);
    //     tb.animate({transform: "R0, " + tb_bbox.x2 + "," + tb_bbox.y2 + " "}, 200, mina.easeinout, function () {
    //         c.animate({transform: "S1.25 1.25, " + c.getBBox().cx + ", " + c.getBBox().cy + " T" + (15 - c_bbox.cx) + " " + (15 - c_bbox.cy) + " "}, 200, mina.easeinout);
    //         b.animate({transform: "T" + (15 - b_bbox.cx) + " " + (15 - b_bbox.cy) + " "}, 200, mina.easeinout, function () {
    //             b1.animate({transform: "R90, " + b1.getBBox().cx + "," + b1.getBBox().cy + " "}, 200, mina.easeinout);
    //         });
    //     });
    // }
    //
    // buttonStateSearch() {
    //     var s  = this.svg();
    //     var b  = s.select('#branches');
    //     var c  = s.select('#circle');
    //     var b1 = s.select('#branch1');
    //
    //     b1.animate({transform: "R180, " + b1.getBBox().cx + "," + b1.getBBox().cy + " "}, 200, mina.easeinout, function () {
    //         b1.attr({transform: "R0, " + b1.getBBox().cx + "," + b1.getBBox().cy + " "});
    //         b.animate({transform: "T0"}, 200, mina.easeinout);
    //         c.animate({transform: "T0 0S1 1," + c.getBBox().cx + ", " + c.getBBox().cy + ""}, 200, mina.easeinout);
    //     });
    // }

    // ==========================================================================
    // Scroll Handling
    // ==========================================================================

    scrollTo(event) {
        var ele    = $(event.currentTarget);
        var target = $("#" + ele.attr('data-scroll') + "");

        if (target) {
            $('.js-search-results').animate({
                scrollTop: target.position().top
            }, 500);
        }
    }


    // Destroy
    // ==========================================================================
    destroy() {
        this.closeResult();
        this.$el.off('.mmes.search');
        $(document).off('.mmes.search');
    }
}

export default Search;
 
