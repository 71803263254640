// ==========================================================================
// resource module
// ==========================================================================
import Module from './Module';

class AnchorScroll extends Module {
    constructor(options) {
        super();
        this.$el = options.$el;

        this.init();
    }

    // ==========================================================================
    // Initialisation
    // ==========================================================================

    init() {
        this.events()
    }

    // ==========================================================================
    // Event Listeners
    // ==========================================================================

    events() {
        $(document).on('click.mmes.resource', '.js-scroll-to', (e) => {
            this.scrollTo(e);
        });
    }

    // ==========================================================================
    // Scroll Handling
    // ==========================================================================

    scrollTo(event) {
        var ele    = $(event.currentTarget);
        var target = $("#" + ele.attr('data-scroll') + "");

        if (target) {
            $("html, body").animate({
                scrollTop: target.offset().top
            }, 500);
        }
    }

    destroy() {
        this.$el.off('.mmes.resource');
        $(document).off('.mmes.resource');
    }
}

export default AnchorScroll;
