export default class App {
    constructor(modules) {
        this.modules = modules;
        this.currentModules = [];
    }

    init() {
        this.initGlobals();
        this.initModules();
    }

    // Init globals
    // ==========================================================================
    initGlobals() {
        if (this.modules['Globals']) {
            this.globals = new this.modules['Globals'];
        }
    }

    // Init modules
    // ==========================================================================
    initModules(scope) {
        // Elements with module
        if (scope) {
            if (typeof scope.querySelectorAll === 'function') {
                var moduleEls = scope.querySelectorAll('[data-module]');
            }
        }

        // No scope, or invalid scope.
        if (typeof moduleEls === 'undefined') {
            var moduleEls = document.querySelectorAll('[data-module]');
        }

        // Loop through elements
        var i = 0;
        var elsLen = moduleEls.length;

        for (; i < elsLen; i++) {

            // Current element
            let el = moduleEls[i];

            // All data- attributes considered as options
            let options = this.getElemData(el);

            // Add current DOM element and jQuery element
            options.el = el;
            options.$el = $(el);

            // Module does exist at this point
            let attr = options.module;

            // Splitting modules found in the data-attribute
            let moduleIdents = attr.split(/\s*,+\s*/).map((m) => m.trim()).filter((m) => m);

            // Loop modules
            let j = 0;
            let modulesLen = moduleIdents.length;

            for (; j < modulesLen; j++) {
                let moduleAttr = moduleIdents[j];
                var modules = $(el).data('_modules');

                // Module already instanciated.
                if (modules) {
                    if (typeof modules[moduleAttr] != 'undefined') {
                        // Maybe reinit?
                        continue;
                    }
                }

                if (!modules) {
                    modules = {};
                }

                if (typeof this.modules[moduleAttr] === 'function') {
                    let module = new this.modules[moduleAttr](options);
                    modules[moduleAttr] = module;
                    this.currentModules.push(module);
                    $(el).data('_modules', modules);
                }
            }
        }

        return this;
    }

    /**
     * Get element data attributes
     * @param   {DOMElement}  node
     * @return  {Array}       data
     */
    getElemData(node) {
        // All attributes
        var attributes = node.attributes;

        // Regex Pattern
        var pattern = /^data\-(.+)$/;

        // Output
        var data = {};

        for (let i in attributes) {
            if (!attributes[i]) {
                continue;
            }

            // Attributes name (ex: data-module)
            let name = attributes[i].name;

            // This happens.
            if (!name) {
                continue;
            }

            let match = name.match(pattern);
            if (!match) {
                continue;
            }

            // If this throws an error, you have some
            // serious problems in your HTML.
            data[match[1]] = node.getAttribute(name);
        }

        return data;
    }
}
