// ==========================================================================
// ArrowAnimation module
// ==========================================================================
import Module from './Module';

class LoaderAnimation extends Module {
    constructor(options) {
        super();
        this.$el = options.$el;
        // this.arrow = this.$el.find('#arrow_anim');

        this.s = null;

        this.init();
    }

    // ==========================================================================
    // Initialisation
    // ==========================================================================

    init() {

        var s  = this.svg();
        // var top = this.top;
        // var left = this.left;
        // var right = this.right;
        // var bottom = this.bottom;

        // bt.attr({transform: "R-45, " + this.bt_bbox.x2 + " " + this.bt_bbox.y2 + ""});
        // bb.attr({transform: "R45, " + this.bb_bbox.x2 + " " + this.bb_bbox.y + ""});
        // a.attr({transform: "T-"+ this.s_bbox.w +" 0"});

        // $(document).ready(() => {
            this.loaderAnimation();
        // });

        this.events();
    }

    svg() {
        if (!this.s) {
            this.s  = Snap(this.$el.find('.js-loader_anim').get(0));
            this.s_bbox = this.s.getBBox();
            this.top = this.s.select('.top');
            this.top_bbox = this.top.getBBox();
            this.left = this.s.select('.left');
            this.left_bbox = this.left.getBBox();
            this.bottom = this.s.select('.bottom');
            this.bottom_bbox = this.bottom.getBBox();
            this.right = this.s.select('.right');
            this.right_bbox = this.right.getBBox();

        }
        return this.s;
    }

    // ==========================================================================
    // Event Listeners
    // ==========================================================================

    events() {
        // this.$el.on('mouseenter.mmes.arrowanimation', () => {
        //     this.showArrow();
        // });
        //
        // this.$el.on('mouseleave.mmes.arrowanimation', () => {
        //     this.hideArrow();
        // });

    }

    // ==========================================================================
    // Animations
    // ==========================================================================

    loaderAnimation() {
        var self = this;

        self.bottom.animate({transform: "S1.2 1.2, " + self.bottom_bbox.cx + ", " + self.bottom_bbox.y + "" , opacity: 0.5}, 200, mina.easeinout, () => {
            self.bottom.animate({transform: "S1 1, " + self.bottom_bbox.cx + ", " + self.bottom_bbox.y + "", opacity: 0.2 }, 200, mina.easeinout);
        });

        setTimeout(function(){
            self.left.animate({transform: "S1.2 1.2, " + self.left_bbox.x + self.left_bbox.w + ", " + self.left_bbox.cy + "", opacity: 0.5 }, 200, mina.easeinout, () => {
                self.left.animate({transform: "S1 1, " + self.left_bbox.x + self.left_bbox.w + ", " + self.left_bbox.cy + "", opacity: 0.2 }, 200, mina.easeinout);
            });
        }, 120);

        setTimeout(function(){
            self.right.animate({transform: "S1.2 1.2, " + self.right_bbox.x + ", " + self.right_bbox.cy + "", opacity: 0.5 }, 200, mina.easeinout, () => {
                self.right.animate({transform: "S1 1, " + self.right_bbox.x + ", " + self.right_bbox.cy + "", opacity: 0.2 }, 200, mina.easeinout);
            });
        }, 240);

        setTimeout(function(){
            self.top.animate({transform: "S1.2 1.2, " + self.top_bbox.cx + ", " + self.top_bbox.y + self.top_bbox.h + "", opacity: 0.5 }, 200, mina.easeinout, () => {
                self.top.animate({transform: "S1 1, " + self.top_bbox.cx + ", " + self.top_bbox.y + self.top_bbox.h + "", opacity: 0.2 }, 200, mina.easeinout);
            });
        }, 360);

        setTimeout(function(){
            self.loaderAnimation();
        }, 1000);
    }

    showArrow() {
        this.s.stop();
        this.a.animate({transform: "T0 0"}, 200, mina.easeinout, () => {
            this.bt.animate({transform: "R0, " + this.bt_bbox.x2 + " " + this.bt_bbox.y2 + ""}, 500, mina.bounce);
            this.bb.animate({transform: "R0, " + this.bb_bbox.x2 + " " + this.bb_bbox.y + ""}, 500, mina.bounce);
        });
    }

    hideArrow() {
        this.s.stop();
        this.a.animate({transform: "T-"+ this.s_bbox.w +" 0"}, 200, mina.easeinout);
        this.bt.animate({transform: "R-45, " + this.bt_bbox.x2 + " " + this.bt_bbox.y2 + ""}, 500, mina.bounce);
        this.bb.animate({transform: "R45, " + this.bb_bbox.x2 + " " + this.bb_bbox.y + ""}, 500, mina.bounce);
    }

    destroy() {
        this.$el.off('.mmes.arrowanimation');
        $(document).off('.mmes.arrowanimation');
    }

}

export default LoaderAnimation;
