// ==========================================================================
// Pagetransitions module
// ==========================================================================
import Module from './Module'

class PageTransitions extends Module {
    constructor(options) {
        super();

        $(document).ready(this.load.bind(this));

        let self  = this,
            $main = $('#js-wrapper'),
            smoothState;

        // Rewrite onPopstate

        window.onpopstate = function (event) {
            if (event.state !== null) {
                var url       = window.location.href,
                    $page     = $('#' + event.state.id),
                    page      = $page.data('smoothState'),
                    utility   = $.smoothStateUtility,
                    diffUrl   = (page.href !== url && !utility.isHash(url, page.href)),
                    diffState = (event.state !== page.cache[page.href].state);

                if (diffUrl || diffState) {
                    if (diffState) {
                        page.clear(page.href);
                    }
                    $('.js-transition').addClass('is-loading');
                    page.load(url, false);
                }
            }
        };

        this.options = {
            blacklist : 's-wysiwyg a',
            onBefore  : function (currentTarget, $container) {

                // TweenMax.killAll(false,true,false);
                $('.js-transition').addClass('is-loading');

                if (currentTarget.data('link-section') === $container.find('[data-section]').data('section')) {
                    $('body').addClass('same-section');
                    $('.js-transition').addClass('same-section');
                    this.onStart.duration    = 1000;
                    this.onProgress.duration = 0;
                    this.onReady.duration    = 0;
                    $("html, body").animate({scrollTop: 0}, "fast");
                } else {
                    this.onStart.duration    = 500;
                    this.onProgress.duration = 500;
                }

                if (currentTarget.data('link-color') === 'primary') {
                    $('.js-wrapper > div').attr('data-loader-color', 'primary');
                }

                window.app.globals.menu.destroy();
                smoothState = $main.smoothState(self.options).data('smoothState');
            },
            onStart   : {
                duration: 100,

                render: function ($container) {
                    $('body').removeClass('dom-loaded').addClass('only-first-time');
                }
            },
            onProgress: {
                // How long this animation takes
                duration: 500,
                // A function that dictates the animations that take place
                render  : function ($container) {
                    // reset dom-loaded
                }
            },
            onReady   : {
                duration: 200,
                // `$container` is a `jQuery Object` of the the current smoothState container
                // `$newContent` is a `jQuery Object` of the HTML that should replace the existing container's HTML.
                render  : function ($container, $newContent) {

                    // Check all current modules and destroy them
                    var currentModules = window.app.currentModules;
                    for (var i = 0; i < currentModules.length; i++) {

                        if (typeof currentModules[i].destroy === 'function') {
                            currentModules[i].destroy();
                        }
                    }

                    // Update the HTML on the page
                    $container.html($newContent);

                    // setTimeout(() => {
                    //     // window.app.globals.animations.load();
                    //     window.app.globals.animations.refresh();
                    // }, 100);
                    // $('html,body').css('overflow', 'visible');
                }
            },
            onAfter   : function ($container, $newContent) {
                window.app.initModules();

                let g = $('.g-recaptcha');

                if (g.length) {
                    try {
                        grecaptcha.render(g.get(0), {sitekey: g.data('sitekey')});
                    } catch (e) {
                    }
                }

                // Launch modules & templates
                $('body').addClass('dom-loaded')
                    .removeClass('same-section')
                    .removeClass('only-first-time');
                $('.js-transition').removeClass('is-loading')
                    .removeClass('same-section');
                setTimeout(() => {
                    window.app.globals.animations.refresh();
                }, 1000);
            }
        };

        $('body').on('click', '.js-link', function (event) {
            var $anchor     = $(event.currentTarget),
                utility     = $.smoothStateUtility,
                modifierKey = (event.altKey || event.ctrlKey || event.metaKey || event.shiftKey);

            // Ctrl (or Cmd) + click must open a new tab
            if (!modifierKey) {
                var smoothState = $main.smoothState(self.options).data('smoothState'),
                    request     = $anchor.prop('href');

                // stopPropagation so that event doesn't fire on parent containers.
                event.stopPropagation();
                event.preventDefault();

                if (self.options.onBefore) {
                    self.options.onBefore($anchor, $main);
                }

                // Check all current modules and destroy them
                // var currentModules = window.app.currentModules;
                // for (var i = 0; i < currentModules.length; i++) {
                //     console.log("loop 2 : " , currentModules[i]);
                //     if (typeof currentModules[i].destroy === 'function') {
                //         currentModules[i].destroy();
                //     }
                // }

                smoothState.load(request);
            }
        });

        // Initialize SmoothState
        smoothState = $main.smoothState(self.options).data('smoothState');

    }

    load() {
        setTimeout(function () {
            $('body').addClass('dom-loaded');
        }, 100);
    }

    reinit() {

        $('body').unbind('click');
        $(window).unbind('resize', this.resize);
        $(window).unbind('scroll', this.scroll);

    }


    // Destroy
    // ==========================================================================
    destroy() {

    }
}

export default PageTransitions;

