// ==========================================================================
// Slider module
// ==========================================================================
import Module from './Module';

class Slider extends Module {
    constructor(options) {
        super();
        this.$el = options.$el;

        this.slider = null;
        this.sliderPaging = null;
        this.sliderDot = null;

        this.displayArrow = this.$el.data('show-arrow') || false;

        this.init();
    }

    // ==========================================================================
    // Initialisation
    // ==========================================================================

    init() {
        this.slider = this.$el.find('.js-slider_container').slick({
            dots:          true,
            arrows:        this.displayArrow,
            prevArrow:     '<button type="button" class="slick-prev slick-custom-prev" aria-label="Précédent"><svg aria-hidden="true" focusable="false"><use xlink:href="assets/images/sprite.svg#arrow-left"></use></svg></button>',
            nextArrow:     '<button type="button" class="slick-next slick-custom-next" aria-label="Suivant"><svg aria-hidden="true" focusable="false"><use xlink:href="assets/images/sprite.svg#arrow-right"></use></svg></button>',
            appendDots:    $('.js-slider_paging'),
            infinite:      true,
            speed:         300,
            autoplay:      true,
            autoplaySpeed: 5000
        });

        this.sliderPaging = this.$el.find('.js-slider_paging');

        this.sliderDot = this.sliderPaging.find('li').first();

        this.events()
    }

    // ==========================================================================
    // Event Listeners
    // ==========================================================================

    events() {
        this.slider.on('beforeChange.mmes.slider', this.afterChange.bind(this))
    }

    afterChange(event, slick, currentSlide, nextSlide) {

        var index = (nextSlide);
        var offset = index * this.sliderDot.height() * -1;

        this.sliderPaging.css('transform', 'translateY(' + offset + 'px)');
    }

    // Destroy
    // ==========================================================================

    destroy() {
        this.$el.off('.mmes.slider');
        $(document).off('.mmes.slider');
    }

}

export default Slider;
