// ==========================================================================
// ArrowAnimation module
// ==========================================================================
import Module from './Module';

class ArrowAnimation extends Module {
    constructor(options) {
        super();
        this.$el = options.$el;
        // this.arrow = this.$el.find('#arrow_anim');

        this.s = null;

        this.init();
    }

    // ==========================================================================
    // Initialisation
    // ==========================================================================

    init() {
        var isMobile = false; //initiate as false
// device detection
        if($(window).width() <= 1000) {
            isMobile = true;
        }

        if(isMobile) {
            return false;
        }

        if (!document.getElementsByClassName("js-arrow_anim").length) {
            return false;
        }

        var s  = this.svg();
        var bt = this.bt;
        var bb = this.bb;
        var a  = this.a;

        bt.attr({transform: "R-45, " + this.bt_bbox.x2 + " " + this.bt_bbox.y2 + ""});
        bb.attr({transform: "R45, " + this.bb_bbox.x2 + " " + this.bb_bbox.y + ""});
        a.attr({transform: "T-"+ this.s_bbox.w +" 0"});

        this.events();
    }

    svg() {
        if (!this.s) {
            this.s  = Snap(this.$el.find('.js-arrow_anim').get(0));
            this.s_bbox = this.s.getBBox();
            this.bt = this.s.select('#top-branch');
            this.bt_bbox = this.bt.getBBox();
            this.bm = this.s.select('#middle-branch');
            this.bm_bbox = this.bm.getBBox();
            this.bb = this.s.select('#bottom-branch');
            this.bb_bbox = this.bb.getBBox();
            this.a  = this.s.select('#arrow');
        }
        return this.s;
    }

    // ==========================================================================
    // Event Listeners
    // ==========================================================================

    events() {
        this.$el.on('mouseenter.mmes.arrowanimation', () => {
            this.showArrow();
        });

        this.$el.on('mouseleave.mmes.arrowanimation', () => {
            this.hideArrow();
        });

    }

    // ==========================================================================
    // Animations
    // ==========================================================================

    showArrow() {
        this.s.stop();
        this.a.animate({transform: "T0 0"}, 200, mina.easeinout, () => {
            this.bt.animate({transform: "R0, " + this.bt_bbox.x2 + " " + this.bt_bbox.y2 + ""}, 500, mina.bounce);
            this.bb.animate({transform: "R0, " + this.bb_bbox.x2 + " " + this.bb_bbox.y + ""}, 500, mina.bounce);
        });
    }

    hideArrow() {
        this.s.stop();
        this.a.animate({transform: "T-"+ this.s_bbox.w +" 0"}, 200, mina.easeinout);
        this.bt.animate({transform: "R-45, " + this.bt_bbox.x2 + " " + this.bt_bbox.y2 + ""}, 500, mina.bounce);
        this.bb.animate({transform: "R45, " + this.bb_bbox.x2 + " " + this.bb_bbox.y + ""}, 500, mina.bounce);
    }

    destroy() {
        this.$el.off('.mmes.arrowanimation');
        $(document).off('.mmes.arrowanimation');
    }

}

export default ArrowAnimation;
