// ==========================================================================
// GoogleMap module
// ==========================================================================
import Module from './Module';

class GoogleMap extends Module {
    constructor(options) {
        super();
        this.$el = options.$el;

        this.init();
    }

    // ==========================================================================
    // Initialisation
    // ==========================================================================

    init() {

        // Async loading
        if (typeof google === 'undefined') {
            // If google is undefined,
            window._tmp_google_onload = () => {
                this.init()
            };

            $.getScript(
                'https://maps.googleapis.com/maps/api/js?sensor=true&v=3' +
                '&language=fr&callback=_tmp_google_onload&key=AIzaSyDh-r13jBKQ4RgyUhgOr4UN2g5TPTTi8KI',
                function () {
                }
            );

            return false;
        }

        this.events();

        var dataCoords = this.$el.data('coords') || [45.496097, -73.550460];

        var coords = new google.maps.LatLng(dataCoords[0], dataCoords[1]);

        // var styleArray = [{ "featureType": "all", "elementType": "labels.text.fill", "stylers": [{ "saturation": 36}, { "color": "#333333"}, { "lightness": 40}]}, { "featureType": "all", "elementType": "labels.text.stroke", "stylers": [{ "visibility": "on"}, { "color": "#ffffff"}, { "lightness": 16}]}, { "featureType": "all", "elementType": "labels.icon", "stylers": [{ "visibility": "off"}]}, { "featureType": "administrative", "elementType": "geometry.fill", "stylers": [{ "color": "#fefefe"}, { "lightness": 20}]}, { "featureType": "administrative", "elementType": "geometry.stroke", "stylers": [{ "color": "#fefefe"}, { "lightness": 17}, { "weight": 1.2}]}, { "featureType": "landscape", "elementType": "geometry", "stylers": [{ "color": "#f5f5f5"}, { "lightness": 20}]}, { "featureType": "poi", "elementType": "geometry", "stylers": [{ "color": "#f5f5f5"}, { "lightness": 21}]}, { "featureType": "poi.park", "elementType": "geometry", "stylers": [{ "color": "#dedede"}, { "lightness": 21}]}, { "featureType": "road.highway", "elementType": "geometry.fill", "stylers": [{ "color": "#ffffff"}, { "lightness": 17}]}, { "featureType": "road.highway", "elementType": "geometry.stroke", "stylers": [{ "color": "#ffffff"}, { "lightness": 29}, { "weight": 0.2}]}, { "featureType": "road.arterial", "elementType": "geometry", "stylers": [{ "color": "#ffffff"}, { "lightness": 18}]}, { "featureType": "road.local", "elementType": "geometry", "stylers": [{ "color": "#ffffff"}, { "lightness": 16}]}, { "featureType": "transit", "elementType": "geometry", "stylers": [{ "color": "#f2f2f2"}, { "lightness": 19}]}, { "featureType": "water", "elementType": "geometry", "stylers": [{ "color": "#e9e9e9"}, { "lightness": 17}]}];

        var coolGray = [{
            "featureType": "landscape",
            "elementType": "labels",
            "stylers"    : [{"visibility": "off"}]
        }, {
            "featureType": "transit",
            "elementType": "labels",
            "stylers"    : [{"visibility": "off"}]
        }, {"featureType": "poi", "elementType": "labels", "stylers": [{"visibility": "off"}]}, {
            "featureType": "water",
            "elementType": "labels",
            "stylers"    : [{"visibility": "off"}]
        }, {
            "featureType": "road",
            "elementType": "labels.icon",
            "stylers"    : [{"visibility": "off"}]
        }, {"stylers": [{"hue": "#00aaff"}, {"saturation": -100}, {"gamma": 2.15}, {"lightness": 12}]}, {
            "featureType": "road",
            "elementType": "labels.text.fill",
            "stylers"    : [{"visibility": "on"}, {"lightness": 24}]
        }, {"featureType": "road", "elementType": "geometry", "stylers": [{"lightness": 57}]}];

        var map = new google.maps.Map(this.$el.find('.js-map').first().get(0), {
            center           : coords,
            scrollwheel      : false,
            styles           : coolGray,
            zoomControl      : false,
            mapTypeControl   : false,
            scaleControl     : false,
            streetViewControl: false,
            rotateControl    : false,
            fullscreenControl: false,
            zoom             : 15
        });

        let markersCoords = this.$el.data('markers') ? this.$el.data('markers') : [dataCoords];

        this.markerCustom();

        for (let i = 0; i < markersCoords.length; i++) {

            var overlay = new this.customMarker(
                new google.maps.LatLng(markersCoords[i][0], markersCoords[i][1]),
                map,
                {
                    'marker': this.$el.find('.js-marker').get(i)
                }
            );
        }

    }

    // ==========================================================================
    // Event Listeners
    // ==========================================================================

    events() {

    }

    // ==========================================================================
    // CustomMarker
    // ==========================================================================

    markerCustom() {

        if (typeof this.customMarker === "function") {
            return this.customMarker;
        }

        this.customMarker = function (latlng, map, args) {
            this.latlng = latlng;
            this.args   = args;
            this.setMap(map);

        };

        this.customMarker.prototype = new google.maps.OverlayView();

        this.customMarker.prototype.draw = function () {

            var self = this;

            var div = this.div;

            if (!div) {

                if (this.args['marker']) {
                    div = this.args['marker'];
                } else {
                    div = this.div = document.createElement('div');
                    div.className      = 'marker';
                    div.style.position = 'absolute';
                    div.style.cursor   = 'pointer';
                }

                if (typeof(self.args.marker_id) !== 'undefined') {
                    div.dataset.marker_id = self.args.marker_id;
                }

                google.maps.event.addDomListener(div, "click", function (event) {
                    google.maps.event.trigger(self, "click");
                });

                var panes = this.getPanes();
                panes.overlayImage.appendChild(div);
            }

            var point = this.getProjection().fromLatLngToDivPixel(this.latlng);

            if (point) {
                div.style.left = point.x + 'px';
                div.style.top  = point.y + 'px';
            }
        };

        this.customMarker.prototype.remove = function () {
            if (this.div) {
                this.div.parentNode.removeChild(this.div);
                this.div = null;
            }
        };

        this.customMarker.prototype.getPosition = function () {
            return this.latlng;
        };

        return this.customMarker;

    }

}

export default GoogleMap;
